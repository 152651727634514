const role = localStorage.getItem('role')
const menuRole = JSON.parse(localStorage.getItem('menu_role'))

let submenuDashboard = null
if (role === 'client' || role === 'corporate') {
  const manageUser = menuRole.filter(item => item.menu.alias === 'Dashboard').length
  if (manageUser === 0) {
    submenuDashboard = {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'HomeIcon',
      isNavMenuHidden: !(manageUser > 0),
    }
  }
} else {
  submenuDashboard = {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    isNavMenuHidden: false,
  }
}

let submenuBanner = null
if (role === 'client' || role === 'corporate') {
  const manageUser = menuRole.filter(item => item.menu.alias === 'Banner').length
  submenuBanner = {
    title: 'Manage Banner',
    icon: 'ImageIcon',
    route: 'banner',
    isNavMenuHidden: !(manageUser > 0),
  }
} else {
  submenuBanner = {
    title: 'Manage Banner',
    icon: 'ImageIcon',
    route: 'banner',
    isNavMenuHidden: false,
  }
}

// const submenuLog = null
// if(role === 'client' || role == 'corporate') {
//   submenuLog = {};
// } else {
//   submenuLog = {
//     title: "Log Corporate",
//     icon: "ImageIcon",
//     route: "log-corporate",
//     isNavMenuHidden: false,
//   };
// }

let submenuSurvey = null
if (role === 'client' || role === 'corporate') {
  submenuSurvey = {
    title: 'Manage Questioner',
    icon: 'DatabaseIcon',
    route: 'questioner',
    isNavMenuHidden: false,
  }
} else {
  submenuSurvey = {
    title: 'Manage Questioner',
    icon: 'DatabaseIcon',
    route: 'questioner',
    isNavMenuHidden: false,
  }
}

let submenuUser = []
// if(role === 'client' || role == 'corporate') {
//   const manageUser = menuRole.filter(item => item.menu.alias === 'Manage User')
//   if (manageUser) {
//     manageUser.forEach(element => {
//       submenuUser.push({
//         title: element.menu.title,
//         route: element.menu.slug,
//         isNavMenuHidden: false,
//       });
//     })
//   }
// } else {
const item = [
  { title: 'User Corporate', route: 'user-corporate', isNavMenuHidden: false },
  { title: 'User Member', route: 'user-member', isNavMenuHidden: false },
]

submenuUser = [...item]
// }

let submenuLeaderboard = []
if (role === 'client' || role === 'corporate') {
  const manageUser = menuRole.filter(menurole => menurole.menu.alias === 'Leaderboard')
  if (manageUser) {
    manageUser.forEach(element => {
      submenuLeaderboard.push({
        title: element.menu.title,
        route: element.menu.slug,
        isNavMenuHidden: false,
      })
    })
  }
} else {
  const itemLeaderboard = [
    { title: 'Top Transaction', route: 'leader-top-transaction', isNavMenuHidden: false },
    { title: 'Top Reseller', route: 'leader-top-reseller', isNavMenuHidden: false },
  ]

  submenuLeaderboard = [...itemLeaderboard]
}

let submenuPartner = []
if (role === 'client' || role === 'corporate') {
  const manageUser = menuRole.filter(menurole => menurole.menu.alias === 'Partner')
  if (manageUser) {
    manageUser.forEach(element => {
      submenuPartner.push({
        title: element.menu.title,
        route: element.menu.slug,
        isNavMenuHidden: false,
      })
    })
  }
} else {
  const itemPartner = [
    { title: 'Master Partner', route: 'partner', isNavMenuHidden: false },
    { title: 'Partner Tracking', route: 'partner-tracking', isNavMenuHidden: false },
  ]

  submenuPartner = [...itemPartner]
}

let submenuEvent = []
if (role === 'client' || role === 'corporate') {
  const manageUser = menuRole.filter(menurole => menurole.menu.alias === 'Manage Event')
  if (manageUser) {
    manageUser.forEach(element => {
      submenuEvent.push({
        title: element.menu.title,
        route: element.menu.slug,
        isNavMenuHidden: false,
      })
    })
  }
} else {
  const itemEvent = [
    { title: 'Event Data', route: 'event', isNavMenuHidden: false },
    { title: 'Log Event', route: 'log-event', isNavMenuHidden: false },
    { title: 'Log Member', route: 'log-member', isNavMenuHidden: false },
  ]

  submenuEvent = [...itemEvent]
}

let submenuPolling = []
if (role === 'client' || role === 'corporate') {
  const manageUser = menuRole.filter(menurole => menurole.menu.alias === 'Manage Event')
  if (manageUser) {
    submenuPolling = {
      title: 'Manage Polling',
      icon: 'DatabaseIcon',
      route: 'polling',
      isNavMenuHidden: false,
    }
  }
} else {
  submenuPolling = {
    title: 'Manage Polling',
    icon: 'DatabaseIcon',
    route: 'polling',
    isNavMenuHidden: false,
  }
}

let submenuCampaign = []
if (role === 'superadmin') {
  submenuCampaign = {
    title: 'Manage Campaign',
    icon: 'FlagIcon',
    route: 'campaign',
    isNavMenuHidden: false,
  }
}

export default [
  submenuDashboard,
  {
    title: 'Manage User',
    icon: 'UsersIcon',
    children: submenuUser,
  },
  // {
  //   title: "Manage Division",
  //   route: 'division',
  //   icon: "ColumnsIcon",
  // },
  {
    title: 'Leaderboard',
    icon: 'AwardIcon',
    children: submenuLeaderboard,
  },
  {
    title: 'Partner',
    icon: 'UserCheckIcon',
    children: submenuPartner,
  },
  submenuBanner,
  {
    title: 'Manage Event',
    icon: 'CalendarIcon',
    children: submenuEvent,
  },
  submenuSurvey,
  submenuPolling,
  // submenuLog,
  submenuCampaign,
]
